@import "../../../common";

.faq-outer-wrapper {
	background-image: url("../../../../public/assets/images/faq-bg.svg");
	background-color: $white;
	background-repeat: no-repeat;
	min-height: 600px;
	background-size: cover;
	padding: 100px 0 70px;

	.faq-wrapper {
		@include fixed-container;
		flex-direction: column;
		gap: 80px;

		.faq-heading {
			text-align: center;
			max-width: 600px;
			margin: 0 auto;

			.faq-tagline {
				color: $secondary-color;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}

			h3.faq-title {
				margin: 10px 0 15px;
				color: $text-color;
				font-size: 30px;
				font-weight: 500;
				line-height: 42px; /* 140% */
				text-transform: capitalize;
			}

			.faq-description {
				color: $secondary-textColor;
				font-size: 16px;
				line-height: 32px;
			}
		}

		.faq-panel-wrapper {
			display: flex;

			@include respond-to($large-screen) {
				justify-content: center;
			}

			.faq-left-panel {
				display: flex;
				flex: 1;

				img {
					@extend %panel-image;
					max-width: 400px;
				}

				@include respond-to($large-screen) {
					display: none;
				}

			}

			.faq-right-panel {
				max-width: 540px;
				display: flex;
				flex: 1;
			}
		}
	}
}