@import "../../../common";

.choose-container {
	background-image: url("../../../../public/assets/images/bg_web.svg");
	background-color: $white;
	background-repeat: no-repeat;
	min-height: 600px;
	background-size: contain;
	background-position: center top;

	@include respond-to($xlarge-screen) {
		background-image: url("../../../../public/assets/images/bg_tablet.svg");
	}

	@include respond-to($medium-screen) {
		background-image: url("../../../../public/assets/images/bg_mobile.svg");
	}

	.choose-wrapper {
		@include fixed-container;
		padding: 100px 0 70px;
		flex-direction: column;
		gap: 80px;

		.choose-heading {
			text-align: center;
			max-width: 600px;
			margin: 0 auto;

			.choose-tagline {
				color: $secondary-color;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}

			h3.choose-title {
				margin: 10px 0 15px;
				color: $text-color;
				font-size: 30px;
				font-weight: 500;
				line-height: 42px; /* 140% */
				text-transform: capitalize;
			}

			.choose-description {
				color: $secondary-textColor;
				font-size: 16px;
				line-height: 32px;
			}
		}

		.choose-tiles {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.choose-tile-wrapper {
				padding: 0 15px 30px;
				width: 100%;
				max-width: 350px;

				.choose-tile {
					padding: 40px 20px;
					border-radius: 10px;
					background: $white;
					box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
					height: 310px;

					.tile-icon {
						width: 75px;
						height: 75px;
						border-radius: 50%;
						background-color: $primary-color;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							width: 35px;
							height: 35px;
						}
					}

					h3.tile-title {
						color: $text-color;
						font-size: 18px;
						font-weight: 500;
						line-height: 25px;
						margin: 30px 0 15px;
					}

					p.tile-description {
						color: $secondary-textColor;
						font-size: 15px;
						line-height: 28.5px;
					}

					@include respond-to($medium-screen) {
						padding: 20px 10px;
					}
				}

				@include respond-to($medium-screen) {
					width: auto;
					margin: 0 auto 30px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			@include respond-to($medium-screen) {
				justify-content: center;
				flex-direction: column;
			}
		}

		@include respond-to($medium-screen) {
			padding: 80px 0 55px;
			gap: 50px;
		}
	}
}
