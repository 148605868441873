@import "../../../common";

.mission-outer-wrapper {
	background-image: url("../../../../public/assets/images/bg_web.svg");
	background-color: $white;
	background-repeat: no-repeat;
	min-height: 400px;
	background-size: contain;
	background-position: center top;

	@include respond-to($xlarge-screen) {
		background-image: url("../../../../public/assets/images/bg_tablet.svg");
	}

	@include respond-to($medium-screen) {
		background-image: url("../../../../public/assets/images/bg_mobile.svg");
	}

	.mission-state {
		@include fixed-container;
		padding: 100px 0;
		justify-content: space-between;

		.mission-left-panel {
			display: flex;

			img {
				@extend %panel-image;
				width: auto;
				max-width: 400px;
			}

			@include respond-to($large-screen) {
				justify-content: center;
			}
		}

		.mission-right-panel {
			@extend %panel-wrapper;
		}

		@include respond-to($medium-screen) {
			flex-direction: column-reverse;
			padding: 70px 0;
			gap: 60px;

			.mission-right-panel {
				margin: 0 auto;
			}
		}
	}
}