@import "../../../common";

.vision-outer-wrapper {
	background-color:  #f6f3f5;

	.vision-state {
		@include fixed-container;
		padding: 100px 0;
		justify-content: space-between;

      .vision-left-panel {
			@extend %panel-wrapper;
		}

		.vision-right-panel {
			display: flex;

			img {
				@extend %panel-image;
				width: auto;
				max-width: 400px;
			}

			@include respond-to($large-screen) {
				justify-content: center;
			}
		}

		@include respond-to($medium-screen) {
			flex-direction: column;
			padding: 70px 0;
			gap: 60px;

			.vision-left-panel {
				margin: 0 auto;
			}
		}
	}
}