@import "../../../common";

.about-stream-container {
	padding: 100px 0;
	background-color: $white;

	.about-streamline {
		@include fixed-container;
		justify-content: space-between;

		.about-left-panel {
			@extend %panel-wrapper;

			h3.panel-title {
				margin-bottom: 20px;
			}
		}

		.about-right-panel {
			display: flex;

			img {
				@extend %panel-image;
				width: auto;
				max-width: 400px;
			}
		}

		@include respond-to($large-screen) {
			justify-content: center;

			.about-right-panel {
				justify-content: center;
			}
		}

		@include respond-to($medium-screen) {
			.about-right-panel {
				display: none;
			}
		}
	}

	@include respond-to($medium-screen) {
		padding: 50px 0;
	}
}
