@import "../../common";

%menu-list-styles {
	user-select: none;
	list-style-type: none;
	text-align: center;

	& > a {
		user-select: none;
		text-decoration: none;
		color: $white;

		&:hover {
			color: $secondary-color;
		}
	}

	&.active {
		color: $secondary-color;

		& > a {
			color: $secondary-color;
		}
	}
}

.main-header {
	height: $scroll-threshold;
	display: flex;
	flex: 1;
	background-color: $primary-color;
	width: 100%;
	transition: all 1s ease-in-out;
	z-index: 100;

	&.scrolled-position {
		position: fixed;
		top: 0;
		left: 0;
	}

	a.logo {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: auto;

			@include respond-to($small-screen) {
				height: 2rem;
			}
		}
	}

	.header-container {
		@include fixed-container;
		display: flex;
		width: 100%;
		justify-content: space-between;

		ul.header-info {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: px-to-rem(24px);

			li {
				@extend %menu-list-styles;
			}
		}

		.mobile-menu-icon {
			font-size: px-to-rem(28px);
			color: $white;
			display: flex;
			align-items: center;
		}
	}
}

.mobile-menu-list {
	background: $primary-color;
	position: fixed;
	top: $scroll-threshold;
	left: 0;
	height: calc(100vh - $scroll-threshold);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	ul.dialog-mobile-menu {
		list-style-type: none;

		li {
			@extend %menu-list-styles;
			@include font-size-responsive(36px);
			margin-bottom: px-to-rem(50px);

			& > a {
				font-weight: $semi-bold;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
