@import "../../../common";

.contact-outer-wrapper {
	background-color: $white;

	.contact-wrapper {
		@include fixed-container;
		padding: 100px 0 70px;
		flex-direction: column;
		gap: 80px;

		.contact-heading {
			text-align: center;
			max-width: 600px;
			margin: 0 auto;

			.contact-tagline {
				color: $secondary-color;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}

			h3.contact-title {
				margin: 10px 0 15px;
				color: $text-color;
				font-size: 30px;
				font-weight: 500;
				line-height: 42px; /* 140% */
				text-transform: capitalize;
			}

			.contact-description {
				color: $secondary-textColor;
				font-size: 16px;
				line-height: 32px;
			}
		}

		.contact-form-panel {
			display: flex;
			flex-direction: column;
			margin: auto;
			max-width: 730px;
			gap: 30px;
			flex: 1;
			width: 100%;

			.form-field-container {
				display: flex;
				gap: 30px;

				.form-group {
					display: flex;
					flex-direction: column;
					flex: 1;
					justify-content: flex-end;;

					input[type="text"], input[type="email"], textarea {
						border: none;
						border-bottom: 2px solid rgba(0, 0, 0, 0.20);
						min-height: 45px;
						width: 100%;

						&:focus, &:hover, &:active {
							border: none;
							border-bottom: 2px solid rgba(0, 0, 0, 0.20);
							outline: none;
						}
					}

					textarea {
						resize: none;
					}

					span {
						font-size: 12px;
						line-height: 18px;
						color: #8e8e8e;
					}
				}
			}

			.error-wrapper {
				font-size: 12px;
				color: $danger-color;
				font-weight: bold;

				.error-message {
					text-align: center;
					list-style-type: none;
				}
			}

			.form-submit-container {
				display: flex;
				justify-content: center;

				.btn-form-submit {
					@extend .primary;
					height: 48px;

					&:disabled {
						cursor: default;
					}
				}
			}
		}

		@include respond-to($medium-screen) {
			padding: 70px 16px;
		}
	}
}
