.not-found-page {
	min-height: 100vh;
	display: flex;
	padding: 2.5rem;
	justify-content: center;

	.not-found-content {
		display: flex;
		flex-direction: column;
		padding-top: 3rem;

		section {
			margin-top: 5rem;

			h1,
			h3 {
				margin-bottom: 2rem;
			}

			p {
				margin-bottom: 1rem;
			}
		}
	}

	.not-found-image {
		margin-top: 5rem;
		max-height: 60vh;
		padding-left: 1.5rem;

		@media screen and (min-width: 48rem) {
			padding-top: 4rem;
			max-width: 60vw;
		}

		@media screen and (min-width: 64rem) {
			padding-top: 0;
			max-width: 80vw;
		}
	}
}
