@import "common";

body {
	margin: 0;
	font-family: $font-primary;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #fff;
	letter-spacing: px-to-rem(1px);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
	padding: 0;
}

.icon-button {
	background: transparent;
	cursor: pointer;
	border: none;
	user-select: none;

	&:active, &:focus, &:hover {
		background: transparent;
	}
}
