@import "../../../common";

.get-wrapper-outside {
	display: flex;
	background-color: $primary-color;
	min-height: 400px;

	.get-wrapper {
		@include fixed-container;
		align-items: center;
		gap: 15px;

		.get-left-panel {
			flex: 1;

			.desc-info {
				color: $secondary-color;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px; /* 150% */
			}

			.get-title {
				margin: 10px 0 30px;
				color: $white;
				font-size: 30px;
				font-weight: 600;
				line-height: 42px; /* 140% */
			}

			.subscribe-input-wrapper {
				padding: 15px;
				display: flex;
				flex-direction: column;
				gap: 10px;
				border-radius: 35px;
				background-color: $white;
				box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.2);

				.input-wrapper {
					display: flex;
					gap: 15px;
					align-items: center;

					.form-input-field {
						border-radius: 25px;
						border: 1px solid #eee;
						flex: 1;
						background: #fff;
						display: flex;
						gap: 10px;
						padding: 10px 15px;

						input.form-input {
							flex: 1;
							border: none;
							font-size: 16px;
							height: 24px;
							outline: none;

							&:focus,
							&:hover,
							&:active {
								border: none;
								outline: none;
							}
						}
					}

					.btn-form-submit {
						.submit-btn {
							@extend .primary;
							height: 48px;

							&:disabled {
								cursor: default;
							}
						}
					}
				}

				.input-error {
					padding-left: 15px;
					font-size: 12px;
					color: $danger-color;
					font-weight: bold;

					.error-message {
						text-align: left;
					}
				}

				@include respond-to($small-screen) {
					flex-direction: column;
					padding: 30px 0;
				}
			}

			@include respond-to($medium-screen) {
				text-align: center;

				.subscribe-input-wrapper {
					.input-wrapper {
						flex-direction: column;
					}
				}
			}
		}

		.get-right-panel {
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;

			img {
				@extend %panel-image;
			}
		}

		@include respond-to($large-screen) {
			flex-direction: column;
			gap: 50px;
			padding-top: 100px;
		}
	}
}
