@import "../../common";

.footer-outer-wrapper {
	background-color: $primary-color;
	color: $white;
	padding: 110px 0;
	min-height: 245px;

	.footer-wrapper {
		@include fixed-container;
		flex: 1;

		.footer-content {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 15px;
			width: 100%;

			.footer-column {
				font-size: 13px;
				max-width: 350px;
				line-height: 24.5px;
				margin: 0 auto;

				.footer-brand-logo {
					margin-bottom: 12px;
					margin-top: -30px;
					height: 56px;
				}

				h3 {
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px; /* 140% */
					margin-bottom: 10px;
					text-transform: uppercase;

					&.header {
						margin-bottom: 25px;
					}
				}

				P {
					color: #eee;
				}

				ul {
					display: flex;
					margin: 0;
					flex-direction: column;
					gap: 20px;
					list-style-type: none;

					li {
						font-size: 14px;
						font-weight: 400;
						line-height: 26.6px; /* 190% */
						text-decoration: none;
						color: #eee;
					}
				}

				a.link {
					font-size: 14px;
					font-weight: 400;
					line-height: 26.6px; /* 190% */
					text-decoration: none;
					color: #eee;
					transition: all 0.3s ease-in-out;

					&:hover,
					&:focus,
					&:active {
						color: $secondary-color;
						font-size: 15px;
						font-weight: 600;
					}
				}

				.btn-get-in-touch {
					@extend .primary;
					letter-spacing: 1px;
					text-decoration: none;
				}
			}

			.center-aligned {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
			}

			@include respond-to($large-screen) {
				grid-template-columns: repeat(2, 1fr);
				max-width: 768px;
				margin: auto;

				.footer-column {
					margin: 25px 0 0;

					ul {
						gap: 10px;
					}
				}
			}

			@include respond-to($medium-screen) {
				grid-template-columns: 1fr;
				max-width: 540px;

				.footer-column {
					margin-top: 30px;
				}
			}
		}
	}
}
