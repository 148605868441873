@import "../../../common";

.home-banner-container {
	display: flex;
	background: $primary-color;
	min-height: 623px;
	flex: 1;

	.home-banner {
		@include fixed-container;
		width: auto;

		.banner-left-panel {
			display: flex;
			justify-content: center;
			flex-direction: column;
			flex: 1;
			padding: 70px 15px 30px;

			h3 {
				text-transform: capitalize;
				display: flex;
				color: $secondary-color;

				&::before {
					content: "";
					margin-right: 10px;
					border-top: 3px solid $secondary-color;
					justify-content: center;
					margin-top: 8px;
					flex-basis: 30px;
				}
			}

			h1 {
				margin: 36px 0 20px;
				color: $white;
				line-height: 50.5px;
			}

			p {
				margin: 0;
				padding: 0;
				color: #d8d0d0;
				font-size: 18px;
				line-height: 34px;
			}

			.btn-contact-us {
				@extend .primary;
				margin-top: 31px;
				text-decoration: none;
			}

			@media screen and (max-width: $small-screen) {
				padding: 30px 15px;
			}

			@media screen and (max-width: $large-screen) {
				padding: 30px 15px;
			}
		}

		.banner-right-panel {
			flex: 1;
			display: flex;
			justify-content: center;

			img {
				@extend %panel-image;
			}

			@media screen and (max-width: $large-screen) {
				display: none;
			}
		}
	}
}