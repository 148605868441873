@import "../../../common";

.services-out-wrapper {
	background: rgb(255, 255, 255);
	background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(246, 243, 245, 1) 35%, rgba(246, 243, 245, 1) 100%);
	background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(246, 243, 245, 1) 35%, rgba(246, 243, 245, 1) 100%);
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(246, 243, 245, 1) 35%, rgba(246, 243, 245, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f6f3f5", GradientType=1);


	.services-wrapper {
		@include fixed-container;
		padding: 100px 0 70px;
		flex-direction: column;

		@include respond-to($medium-screen) {
			padding: 0;
		}

		.services-content-box {
			display: flex;
			flex-direction: column;
			padding: 50px 15px 20px;
			border-radius: 10px;
			background: #FFF;
			box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.20);
			min-height: 400px;
			gap: 80px;

			.services-heading {
				text-align: center;
				max-width: 600px;
				margin: 0 auto;

				.services-tagline {
					color: $secondary-color;
					font-size: 16px;
					font-weight: 700;
					line-height: 24px;
				}

				h3.services-title {
					margin: 10px 0 15px;
					color: $text-color;
					font-size: 30px;
					font-weight: 500;
					line-height: 42px; /* 140% */
					text-transform: capitalize;
				}

				.services-description {
					color: $secondary-textColor;
					font-size: 16px;
					line-height: 32px;
				}
			}

			.services-option {
				display: grid;
				grid-row-gap: 30px;
				grid-column-gap: 30px;
				padding: 0 15px 30px;
				background-color: transparent;
				grid-template-columns: auto auto auto;

				@include respond-to($xlarge-screen) {
					grid-template-columns: auto auto;
				}

				@include respond-to($medium-screen) {
					grid-template-columns: auto;
				}

				.service-item-wrapper {
					display: flex;
					padding: 30px 20px;
					gap: 25px;
					border-radius: 3px;
					background-color: $white;
					box-shadow: 0 2px 27px 0 rgba(154, 161, 171, 0.18);

					.service-icon {
						min-width: 50px;
						height: 50px;
						border-radius: 50%;
						background-color: $primary-color;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.service-content {
						.service-title {
							font-size: 18px;
							font-weight: $semi-bold;
							line-height: 25px; /* 140% */
							text-transform: capitalize;
							margin-bottom: 8px;
						}

						p.service-content-desc {
							font-size: 14px;
							line-height: 21px; /* 150% */
						}
					}

					@include respond-to($small-screen) {
						flex-direction: column;
						gap: 10px;

						.service-icon {
							align-self: flex-start;
						}

						.service-content {
							.service-title {
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}