@import "../../../common";

.work-streamline {
	padding: 100px 0 70px;

	.streamline-content-wrapper {
		@include fixed-container;
		width: auto;
		padding: 0;

		.streamline-left-panel {
			display: flex;
			flex: 1;

			img {
				@extend %panel-image;
				max-width: 400px;
			}

			@include respond-to($large-screen) {
				justify-content: center;
			}

			@include respond-to($medium-screen) {
				background-color: $white;
			}
		}

		.streamline-right-panel {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding: 10px 0 10px;
			max-width: 540px;

			@include respond-to($large-screen) {
				justify-content: center;
			}

			.streamline-tagline {
				color: $secondary-color;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}

			h3.streamline-title {
				color: $text-color;
				font-size: 30px;
				font-weight: 500;
				line-height: 42px; /* 140% */
				margin: 10px 0 20px;
			}

			p.streamline-desc {
				font-size: 15px;
				color: $secondary-textColor;
				line-height: 28.5px; /* 190% */
			}

			.streamline-services {
				display: grid;
				margin-top: 45px;
				grid-template-columns: auto auto;
				grid-row-gap: 20px;

				@include respond-to($medium-screen) {
					grid-template-columns: auto;
				}

				.service-item {
					display: flex;
					align-items: center;
					gap: 15px;

					span {
						color: #42526e;
						font-size: 1rem;
						font-weight: 500;
						line-height: 20px; /* 125% */
					}
				}
			}
		}

		// special breakpoint because content is in grid mode
		// ideally it should be $medium-screen
		@media (max-width: 928px) {
			flex-direction: column-reverse;
			padding: 0 20px;
			gap: 50px;

			.streamline-right-panel {
				margin: auto;
			}
		}
	}
}