@import "../../common";

.page-banner {
	min-height: px-to-rem(320px);
	background: $primary-color;
	display: flex;
	justify-content: center;
	align-items: center;

	@include respond-to($medium-screen) {
		min-height: px-to-rem(300px);
	}

	.banner-content {
		h1.page-header {
			color: $white;
			margin-bottom: px-to-rem(15px);
			@include font-size-responsive(42px);
			font-family: $font-secondary;
			font-weight: 500;
			text-transform: capitalize;
			line-height: 60px;
		}

		.page-path-wrapper {
			display: flex;
			justify-content: center;
			gap: px-to-rem(8px);

			a, span {
				@include font-size-responsive(13px);
				text-decoration: none;
				text-transform: capitalize;
				color: $white;
			}

			a:hover {
				color: $secondary-color;
				cursor: pointer;
			}
		}
	}
}