$primary-color: #46143c;
$secondary-color: #b99650;
$neutral-color: #143446;
$white: #ffffff;
$black: #000000;
$text-color: #222;
$secondary-textColor: #0b0101;

// Define other colors based on primary colors (if needed)
$dark-primary: darken($primary-color, 10%); // Darken primary color by 10%
$light-primary: lighten($primary-color, 20%); // Lighten primary color by 20%
$danger-color: #eb4034;

// fonts-family
$font-primary: "Archivo";
$font-secondary: "Poppins";

// Define breakpoints for responsiveness
$small-screen: 576px; // Small screens (e.g., smartphones)
$medium-screen: 768px; // Medium screens (e.g., tablets)
$large-screen: 992px; // Large screens (e.g., small laptops/desktops)
$xlarge-screen: 1200px; // Extra-large screens (e.g., large desktops)

// scroll threshold and height of header
$scroll-threshold: 60px;

$semi-bold: 600;
$bold: 800;

// Define responsive styles using mixins
@mixin respond-to($breakpoint) {
	@media (max-width: $breakpoint) {
		@content;
	}
}

@function px-to-rem($value, $base-font-size: 16px) {
	// Calculate the converted value in rems
	@return calc($value / $base-font-size) * 1rem;
}

// Define responsive styles using mixins
@mixin font-size-responsive($base-size) {
	font-size: $base-size;

	@media (max-width: $small-screen) {
		font-size: calc(#{$base-size} * 0.8); // 80% of base size for small screens
	}

	@media (min-width: $medium-screen) {
		font-size: calc(#{$base-size} * 1); // Base size for medium screens
	}

	@media (min-width: $large-screen) {
		font-size: calc(#{$base-size} * 1.2); // 120% of base size for large screens
	}

	@media (min-width: $xlarge-screen) {
		font-size: calc(#{$base-size} * 1.4); // 140% of base size for extra-large screens
	}
}

@mixin fixed-container {
	max-width: $xlarge-screen;
	margin: auto;
	padding: 0 px-to-rem(24px);
	display: flex;

	@include respond-to($medium-screen) {
		padding: 0 px-to-rem(16px);
	}
}

%panel-image {
	width: 100%;

	@include respond-to($medium-screen) {
		width: auto;
		height: auto;
	}

	@include respond-to($small-screen) {
		width: 80%;
		height: auto;
	}
}

.primary {
	color: $white;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 48px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	min-width: 160px;
	align-items: center;
	border-radius: 30px;
	border: 1px solid $white;
	background: $secondary-color;
	cursor: pointer;
	max-width: fit-content;
}

%panel-wrapper {
	max-width: 540px;

	p.panel-tagline {
		color: $secondary-color;
		font-size: 1rem;
		font-weight: 700;
		line-height: 24px; /* 150% */
	}

	h3.panel-title {
		color: $text-color;
		font-size: 30px;
		font-weight: 500;
		line-height: 42px; /* 140% */
		margin-bottom: 16px;
	}

	.p-container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		p {
			color: $text-color;
			font-size: 15px;
			font-weight: 400;
			line-height: 28.5px; /* 190% */
		}
	}
}
