@import "../../common";

.fixed-drop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	min-height: 100vh;
}

.backdrop {
	@extend .fixed-drop;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	.dialog-container {
		display: flex;
		flex-direction: column;
		z-index: 999999;
		border-radius: 10px;
		box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.20);
		justify-content: center;
		align-items: center;
		max-width: 790px;
		background: #fff;
		padding: 60px 64px;

		h3 {
			color: $text-color;
			text-align: center;
			font-size: 30px;
			font-weight: 500;
			line-height: 42px; /* 140% */
			text-transform: capitalize;
		}

		p {
			color: $secondary-textColor;
			text-align: center;
			font-size: 16px;
			line-height: 32px;
			margin: 15px 0;
		}


		.btn-done {
			@extend .primary;
		}

		@include respond-to($medium-screen) {
			padding: 40px 0;
			max-width: 300px;

			h3 {
				font-size: 21px;
				line-height: normal;
			}

			p {
				line-height: normal;
			}
		}
	}
}
