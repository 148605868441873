@import "../../../common";

.variant-content-wrapper {
	display: flex;
	min-height: 500px;
	flex-wrap: wrap;

	.variant-left {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 30px;
		padding: 10px 15px 10px;

		.variant-box-wrapper {
			margin: 0 auto;

			.variant-box {
				border-radius: 5px;
				background: $white;
				box-shadow: 0 2px 27px 0 rgba(154, 161, 171, 0.18);
				padding: 30px;

				display: flex;
				gap: 30px;

				.index-indicator {
					min-width: 30px;
					height: 30px;
					background-color: $secondary-color;
					border-radius: 50%;
					color: $white;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					line-height: 40px;
				}

				.variant-box-content {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.variant-box-heading {
						font-size: 18px;
						font-weight: 500;
						line-height: 25px; /* 140% */
						text-transform: capitalize;
					}

					.variant-box-desc {
						font-size: 14px;
						line-height: 21px;
					}
				}
			}
		}
	}

	.variant-right {
		display: flex;
		flex: 1;
		justify-content: center;

		img {
			@extend %panel-image;
		}

		@include respond-to($medium-screen) {
			background-color: $white;
		}
	}

	@include respond-to($medium-screen) {
		flex-direction: column-reverse;
		gap: 50px;
	}
}
