@import "../../common";

.scroll-top {
	position: fixed;
	bottom: 20px;
	right: 25px;

	button {
		@extend .primary;
		border-radius: 50%;
		min-width: 40px;
		width: 40px;
		height: 40px;

		i {
			font-size: 20px;
			vertical-align: middle;
		}
	}
}
