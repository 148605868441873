@import "../../common";

.accordion-wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 30px;

	.accordion-item {
		width: 100%;

		.accordion-item-header {
			background: rgb(185, 150, 80);
			background: -moz-linear-gradient(90deg, rgba(185, 150, 80, 1) 0%, rgba(70, 20, 60, 1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(185, 150, 80, 1) 0%, rgba(70, 20, 60, 1) 100%);
			background: linear-gradient(90deg, rgba(185, 150, 80, 1) 0%, rgba(70, 20, 60, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b99650", endColorstr="#46143c", GradientType=1);
			border-radius: 25px;
			border: 1px solid #f2f4f8;
			padding: 16px 25px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			gap: 30px;
			color: $white;


		}

		.accord-content-placeholder {
			padding: 30px 25px 0;
		}
	}
}